<template>
  <trust-list @load="load" ref="loadmore"></trust-list>
</template>
<script>
import trustList from "./index";
export default {
  components: {
    trustList
  },
  props:{
    billStatusEnum:String,
    params:{
      type:Object,
      default:() => {}
    }
  },
  computed: {
    loadmore () {
      return this.$refs.loadmore;
    }
  },
  watch:{
    params:{
      handler:function (val){
				if(val.billStatusEnum==='ALL'){
					this.$delete(this.params,'billStatusEnum')
				}
        this.loadmore.refresh(false, true)
      },
      deep:true
    }
  },
  data (){
    return {
      page:0,
      data:[],
      totalNumber:0
    }
  },
  methods: {
    load (page, next) {
      let param = {
        example: {
          ...this.params
        },
        pageable: {
          page: page,
          size: 20
        }
      };
      this.$store
        .dispatch("getBillList", param)
        .then((response) => {
          let { data, totalNumber } = response;
          this.data = data
          this.totalNumber = totalNumber
          next(data, totalNumber);
        })
        .catch((error) => {
          this.loadmore.showRetry();
          console.error(error);
          this.$toast.error(error);
        });
    }
  }
};
</script>
<style module leng="less"></style>
