<template>
  <wt-input
    :clearable="false"
    v-bind="$attrs"
    v-on="$listeners"
    @focus="focus"
    v-model="currentValue"
    :placeholder="placeholder"
    :type="type"
    :autofocus="autofocus"
    :disabled="disabled"
    class="pt-wt-input"
    :maxlength="maxlength"
  >
    <slot name="lt" slot="lt" />
    <slot name="rt" slot="rt" />
  </wt-input>
</template>
<script>
import { Input } from "wt-ui/lib/mobile";
export default {
  name: "pt-input",
  props: {
    value: {},
    type: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean
    },
    autofocus: Boolean,
    maxlength: Number
  },
  data () {
    return {
      currentValue: this.value
    };
  },
  watch: {
    value () {
      this.currentValue = this.value;
    },
    currentValue () {
      this.$emit("input", this.currentValue);
    }
  },
  components: {
    "wt-input": Input
  },
  methods: {
    focus (val) {
      this.$emit("focus", val);
    }
  }
};
</script>
<style lang="less">
.pt-wt-input {
  input::-webkit-input-placeholder {
    font-size: 10px;
    color: #b0b0b0 !important;
  }
}
</style>
