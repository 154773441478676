<template>
  <wt-loadmore ref="loadmore" :on-pulldown="load" :on-pullup="load" :class="$style['trust-list']">
    <template slot-scope="{ data }">
      <div
        :class="$style['list-item']"
        v-for="(item, index) in data"
        :key="index"
      >
        <list-item :data="item" @click.native="goDetail(item.id)"></list-item>
      </div>
    </template>
  </wt-loadmore>
</template>
<script>
import listItem from "./item";
export default {
  computed: {
    loadmore () {
      return this.$refs.loadmore;
    }
  },
  components: {
    listItem
  },
  methods: {
    load (page, next) {
      this.$emit("load", page, next);
    },
    showRetry () {
      this.loadmore.showRetry();
    },
    refresh (val, row){
      this.loadmore.refresh(val, row)
    },
    goDetail (id){
      this.$router.push(`/trust-order-detail/${id}`)
    }
  }
};
</script>
<style lang="less" module>
.trust-list {
  margin-top: 20px;
}
.list-item {
  padding-bottom: 20px;
}
</style>
