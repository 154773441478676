import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./prototype";
import "./axios";
import store from "./store";
import env from "./api";
import _typeof from "formats/typeof.js";
import "./register-global-components";

import "@/utils/toast";
import "@/utils/dialog";
import "@/utils/validate";
import "amfe-flexible";
import "@/less";
import "amfe-flexible";
import "amfe-flexible";
import './assets/iconfont/iconfont.css'
import 'amfe-flexible'
Vue.config.productionTip = false;
// import '../theme/index.css'
window._ = _typeof;

function getUrl () {
  env.then((res) => {
    Vue.axios.defaults.baseURL = res && res.api_server;
    api();
  });
}
// Vue.axios.defaults.baseURL = env.api_server

// 让axios在所有请求的时候带上cookie - 微服务平台需要
function api () {
  Vue.axios.defaults.withCredentials = true;
  new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount("#app");
}
export default getUrl();
