import { sampleStatusList } from "json/sample-options"
import getEnumeration from "formats/enumeration"
import { formatDateObject, formatDate } from '@/utils/formatUtils/date.js'
import { terrace } from 'json/lab-order'
export default {
  getSampleData(data) {
    data.orderStatus = data.orderStatusEnum && getEnumeration(data.orderStatusEnum, sampleStatusList, 'completeText', 'name')
    data.back_text = data.back === null ? '--' : data.back ? '是' : '否'
    data.delivery_date = data.deliveryDate && formatDate(data.deliveryDate, 'date')
    data.nextOrder_action = data.nextOrderAction && getEnumeration(data.nextOrderAction, sampleStatusList, 'operation', 'name')
    data.delivery_type = data.deliveryType ? data.deliveryType.map(e => getEnumeration(e, terrace)).toString() : ''
    return data
  }
}