// 表单工具方法库
import fecha from "fecha";

fecha.masks = {
  default: "YYYY.MM.DD HH:mm",
  shortDatetime: "YYYY-MM-DD HH:mm",
  full: "YYYY-MM-DD HH:mm:ss",
  date: "YYYY-MM-DD",
  time: "HH:mm:ss",
  month: "YYYY-MM",
  hour: "YYYY-MM-DD HH",
  hm: "HH:mm"
};

const GenderOptions = [
  { key: "SECRET", value: "保密" },
  { key: "MALE", value: "男" },
  { key: "FEMALE", value: "女" }
];

/**
 * 获取性别的选项
 */
export function getGenderOptions () {
  return GenderOptions;
}

/**
 * 格式化性别
 * @param {*String or Nunber} code
 */
export function genderFormat (code) {
  switch (String(code)) {
  case "MALE":
    return "男";
  case "FEMALE":
    return "女";
  case "SECRET":
    return "未知";
  }
  for (let item of GenderOptions) {
    if (item.key === code) {
      return item.value;
    }
  }
  return "";
}

/**
 * 将Date对象转换成unix timestamp
 */
export function dateToTimestamp (date) {
  return Math.round(date.getTime());
}
/**
 * 将unix timestamp转换成Date对象
 * @param {int} timestamp
 */
export function timestampToDate (timestamp) {
  return new Date(timestamp);
}
/**
 * 获取当前时间的unix timestamp
 */
export function getCurrentTimestamp () {
  return dateToTimestamp(new Date());
}
/**
 * Convert将指定的date值转换成date对象，可以接受timestamp、string或者date
 */

export function toDate (dateObject) {
  let date = null;
  if (dateObject instanceof Date) {
    date = dateObject;
  } else if (typeof dateObject === "string") {
    date = new Date(dateObject);
  } else if (typeof dateObject === "number") {
    if (dateObject === 0) {
      date = null;
    } else {
      date = timestampToDate(dateObject);
    }
  }
  return date;
}
/**
 * 将unix timestamp或者Date对象格式化成用于前端显示的字符串
 * 可选的格式:
 * 'default' - 'YYYY-MM-DD HH:mm:ss'
 * 'shortDatetime' - 'YYYY-MM-DD HH:mm'
 * 'date' - 'YYYY-MM-DD'
 * 'time' - 'HH:mm:ss'
 */
export function formatDateObject (dateObject, format = "full") {
  if (!dateObject) {
    // console.warn('toData 方法必须接受一个有效且非空的参数')
    return "";
  }
  let date = toDate(dateObject);
  if (date == null) {
    return "";
  }
  return fecha.format(date, format);
}

/**
 * 格式化时间
 * @param {*Date} now
 */
export function formatDate (now) {
  return formatDateObject(now, "date");
}

/**
 * 对对象进行深度拷贝
 * @param {*} data
 */
export function deepClone (data) {
  return JSON.parse(JSON.stringify(data));
}

/**
 * 格式化订单状态用于order-list
 * @param {*} value
 */
export function formatOrderStatus (value) {
  for (let item of orderStatus) {
    if (item.key === value) {
      return item.value;
    }
  }
  return "";
}

/**
 * 格式化剩余时间
 */
export function formatRemainingTime (start, end, format = "D天H小时M分S秒") {
  const S = 1000;
  const M = S * 60;
  const H = M * 60;
  const D = H * 24;
  let remainingTime = end - start;

  function calc (type, val) {
    let d = Math.floor(remainingTime / val);
    if (type === "D" && d <= 0) {
      format = format.replace(/D+天/, "");
    }
    let text = String(d);
    if (type !== "D") {
      text = text.padStart(2, "0");
    }
    format = format.replace(type, text);
    remainingTime = remainingTime % val;
  }
  calc("D", D);
  calc("H", H);
  calc("M", M);
  calc("S", S);
  return format;
}
export function getQueryString (name, fullPath = window.location.search) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = fullPath.substr(1).match(reg);
  if (r !== null) {
    return decodeURI(r[2]);
  }
  return null;
}
// ?foo=bar&baz=bing => {foo: bar, baz: bing}

// // 获取URL的查询参数
// q={};location.search.replace(/([^?&=]+)=([^&]+)/g,(_,k,v)=>q[k]=v);q;
/* 获取某一天及后几天0点0分0秒的时间戳
    date：为时间（某天，格式为时间戳）
    day：多少天之前或之后，number（之后为正数，之前为负数）
*/
export function dateZeroOfDay (date, day) {
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  let dateTime = Date.parse(date)
  if (day) {
    dateTime = dateTime + day * 1000 * 60 * 60 * 24
  }
  return dateTime
}