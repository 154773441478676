export default [{
  path: '/user-setting',
  name: 'user-setting',
  component: resolve => require(['views/user-setting'], resolve),
  meta: { view: 'defaultView', auth: true }
}, {
  path: '/phone-setting',
  name: 'phone-setting',
  component: resolve => require(['views/phone-setting'], resolve),
  meta: { view: 'defaultView', auth: true }
}, {
  path: '/password-setting',
  name: 'password-setting',
  component: resolve => require(['views/password-setting'], resolve),
  meta: { view: 'defaultView', auth: true }
}]