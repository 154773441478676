<template>
  <wt-tabbar :class="$style[classes]">
    <wt-tabbar-item v-for="(item,index) in data" :key="index" :to="item.to">
      <span :class="item.icon.normal" slot="icon-normal"></span>
      <span :class="item.icon.active" slot="icon-active"></span>
      {{item.label}}
    </wt-tabbar-item>
  </wt-tabbar>
</template>

<script>
// 组件class前缀
const prefixCls = 'pt-tabbar'

export default {
  name: 'pt-tabbar',
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  computed: {
    classes () {
      return `${prefixCls}`
    }
  }
}
</script>

<style lang="less" module>
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
@import '~less/var.less';
.pt-tabbar{
	background: #fff;
	:global{
  .icon{
  font-size: 23px !important;
  &-active{
    color: @main-color !important;
	}
	}
  .wt-tabbar-item__text{
    font-size: 13px;
	}
	.router-link-active{
		color: @main-color !important;
		.wt-tabbar-item__text{
			color: @main-color !important;
		}
	}
}
}
</style>
