<template>
  <ValidationProvider
    ref="formItem"
    :tag="tag"
    :rules="rules"
    :name="name"
    :class="$style['wt-form-list-item']"
    class="wt-form-list-item"
    v-slot="{ errors }"
  >
    <slot />
    <div class="error-message-div">
      <span class="error-message" v-if="showError && errors && errors.length">{{
        errors[0]
      }}</span>
    </div>
  </ValidationProvider>
</template>
<script>
export default {
  props: {
    tag: String,
    //验证规则
    rules: String,
    //验证字段的名称
    name: String,
    showError: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    formItem () {
      return this.$refs.formItem;
    }
  },
  methods: {
    validate () {
      return this.$refs.formItem.validate((res) => {
        return res;
      });
    }
  }
};
</script>
<style module lang="less">
.wt-form-list-item {
  :global {
    .error-message {
      font-size: 10px;
      color: red;
      text-align: left;
    }
    .error-message-div {
      display: flex;
      padding: 5px 0 0 15px;
    }
  }
}
</style>
