<template>
  <div :class="$style['detail-header']">
    <!-- <h1> -->
      <!-- <img :src="require('@/assets/logo-name.png')" /> -->
			<div :class="$style['header-number']">
			<i class="iconfont icon-bianhao"></i>
      <span>{{'委托单'}}</span>
      <span>{{order.billCode || '--'}}</span>
      <status-tag v-if="order.urgent" class="status-tag" background-color="#FF2D2D" ></status-tag>
      <status-tag v-if="order.overDue" class="status-tag" background-color="#F7B500" text="逾期"></status-tag>
			</div>
    <!-- </h1> -->
    <div :class="$style['trust-content']">
      <dl>
        <dt>{{order.orderNum}}</dt>
        <dd>样品</dd>
      </dl>
      <dl>
        <dt>{{order.labExamItemCount}}</dt>
        <dd>检测项目</dd>
      </dl>
      <dl>
        <dt>{{order.productionTaskCount}}</dt>
        <dd>任务单</dd>
      </dl>
    </div>
    <div :class="$style['time']">
      <div>
        <span>开始日期</span>
        <p>{{order.start_date}}</p>
      </div>
      <div>
        <span>交付日期</span>
        <p>{{order.delivery_date || '--'}}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    order:{
      type:Object,
      default:() => {}
    }
  },
  computed: {
    currentUserMessage () {
      return this.$store.state.currentUserMessage || {};
    }
  }
};
</script>
<style module lang="less">
@import "~less/var.less";
.detail-header {
  background: linear-gradient(to right,#6D71F8,#409EFF);
  border-radius: 5px;
  width: 100%;
  padding: 15px;
  color: #fff;
  font-size: @font-size-normal;
  box-sizing: border-box;
  .header-number{
    display: flex;
    align-items: center;
    margin-bottom: 17px;
    img{
      width: 20px;
    }
    span{
      margin-left: 5px;
      white-space: nowrap;
      // font-size: @font-size-title;
    }
    :global{
      .status-tag {
        margin-left: 5px;
        white-space: nowrap;
      }
    }
  }
  .trust-content{
    display: flex;
    border-bottom: 1px solid #DEDEDE;
    justify-content: space-around;
    padding: 17px 0;
    dt{
      font-size: @font-size-number;
      margin-bottom: 5px;
    }
    dd{
      color:#DEDEDE;
      font-size: @font-size-tip;
    }
  }
  .time{
    display: flex;
    justify-content: space-between;
    margin-top: 17px;
    >div{
      display: flex;
      align-items: center;
    }
    span{
      color:#DEDEDE;
      font-size: @font-size-tip;
      margin-right:5px;
    }
    p{
      font-size: @font-size-title;
    }
  }
}
</style>
