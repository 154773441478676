<template>
  <div :class="$style['pt-date-picker']">
    <wt-input :readonly="true" v-model="readOnlyTime" type="text" @click.native="$refs.datePicker.show()"></wt-input>
    <i class="iconfont icon-riqi"></i>
    <wt-date-picker :title="title" ref="datePicker" v-model="Value" @on-confirm="setDate" :start-date="startDate"></wt-date-picker>
  </div>
 </template>
 
<script>
import { formatDate } from '@/utils/formatUtils/date.js'
export default {
  props: {
    value:[Number, Date],
    title:String
  },
  data () {
    return {
      Value: this.value,
      readOnlyTime:formatDate(this.value) || ''
    }
  },
  computed:{
    startDate () {
      let startDate = new Date();
      return new Date(
        startDate.getFullYear() - 100,
        startDate.getMonth(),
        startDate.getDate(),
        0,
        0,
        0
      );
    }
  },
  watch: {
    value (val) {
      this.Value = val
      this.readOnlyTime = formatDate(val)
    },
    Value:{
      handler:function (val){
        this.$emit('input', val)
      },
      deep:true
    }
  },
  methods: {
    formatDate,
    setDate (val, val1, val2) {
      if (!val2) {
        this.readOnlyTime = formatDate(val)
        this.Value = val
      }
    }
  }
}
</script>
 
 <style module lang="less">
 @import "~less/var.less";
 .pt-date-picker{
   display: flex;
   width: 100%;
   justify-content: space-between;
   align-items: center;
 }
 </style>
 