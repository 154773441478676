<template>
  <wt-box class="wt-select" direction="row" @click.native="showPickerDiv">
    <wt-input v-if='allowSearch' v-model="inputValue" type="text" @focus="focus" :placeholder="placeholder"></wt-input>
    <template v-else>
      <div class="placeholder" v-if="!currentValue">{{placeholder}}</div>
      <template v-else>{{currentValue}}</template>
    </template>
    <!-- <i class="wt-loading wt-select-icon" v-if="loading&&!disabled"></i> -->
    <i class="iconfont icon-xiala wt-select-icon"></i>
  </wt-box>
</template>

<script>
export default {
  name: 'wt-select',
  props: {
    title: {
      type: String,
      default: 'title'
    },
    // [{text:'',value:''}]
    data: {
      type: Array,
      required: true
    },
    value: {
      // 传入的value值将会匹配data中的value
      type: [String, Number, Object]
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    // 在loading状态时（禁止选择）点击本组件，给出的提示，如提示稍后，或请先填写***等
    loadingTip: {
      type: String
    },
    allowSearch: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  data () {
    return {
      currentValue: '',
      inputValue: null,
      isInput: false,
      // 是否是选择后的值
      isSelected: false
    }
  },
  computed: {
    loading () {
      console.log('this.data==', this.data)
      return this.data.length <= 0
    }
  },
  watch: {
    value () {
      this.currentValue = /* this.currentValue || */ this.matchValue()
    },
    data () {
      this.init()
      if (this.isInput && !this.isSelected) {
        this.showPicker()
      }
    },
    inputValue (val, old) {
      if (val !== old) {
        this.$emit('searchChange', val)
        if (this.allowSearch) {
          this.isInput = true
        }
        if (!val) {
          this.isSelected = false
        }
      }
      this.picker.hide()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    focus () {
      this.showPicker()
    },
    matchValue () {
      if (_.isObject(this.value)) {
        let patter = this.data.find(item => JSON.stringify(item.value) === JSON.stringify(this.value)) || {}
        return patter.text
      }
      let patter = this.data.find(item => item.value === this.value) || {}
      return patter.text
    },
    init () {
      this.currentValue = this.matchValue()
      let selectedIndex = []
      this.data.forEach((item, index) => {
        if (item.text === this.currentValue) {
          selectedIndex[0] = index
        }
      })
      this.picker = this.$createPicker({
        title: this.title,
        data: [this.data],
        selectedIndex: selectedIndex,
        onSelect: (selectedVal, selectedIndex, selectedText) => {
          this.currentValue = selectedText[0]
          this.isSelected = true
          if (this.allowSearch) {
            this.inputValue = selectedText[0]
          }
          this.$emit('input', selectedVal[0])
        },
        onCancel: () => {
          this.$emit('on-cancel')
        }
      })
    },
    showPickerDiv () {
      if (!this.allowSearch && !this.disabled) {
        this.showPicker()
      }
    },
    showPicker () {
      if (this.loading) {
        return this.loadingTip && this.$toast.info(this.loadingTip)
      }
      this.picker.show()
    }
  }
}
</script>
<style lang="less" scoped>
.placeholder {
  color: #d5d5d5;
  font-size: 13px;
}
.wt-select{
  justify-content: space-between;
}
.wt-select-icon{
	width: 1em;
	height: 1em;
	font-size:1.3em;
	color: #ADADAD;
	transform: scale(0.6);
}
</style>
