import fecha from "fecha";

fecha.setGlobalDateMasks({
  default: "YYYY.MM.DD HH:mm",
  shortDatetime: "YYYY-MM-DD HH:mm",
  full: "YYYY-MM-DD HH:mm:ss",
  date: "YYYY-MM-DD",
  dateSlash: 'YYYY/MM/DD',
  time: "HH:mm:ss",
  month: "YYYY-MM",
  hour: "YYYY-MM-DD HH",
  hm: "HH:mm"
});

/**
 * 将Date对象转换成unix timestamp
 */
export function dateToTimestamp (date) {
  return Math.round(date.getTime());
}
/**
 * 将unix timestamp转换成Date对象
 * @param {int} timestamp
 */
export function timestampToDate (timestamp) {
  return new Date(timestamp);
}
/**
 * 获取当前时间的unix timestamp
 */
export function getCurrentTimestamp () {
  return dateToTimestamp(new Date());
}
/**
 * Convert将指定的date值转换成date对象，可以接受timestamp、string或者date
 */

export function toDate (dateObject) {
  let date = null;
  if (dateObject instanceof Date) {
    date = dateObject;
  } else if (typeof dateObject === "string") {
    date = new Date(dateObject);
  } else if (typeof dateObject === "number") {
    if (dateObject === 0) {
      date = null;
    } else {
      date = timestampToDate(dateObject);
    }
  }
  return date;
}
/**
 * 将unix timestamp或者Date对象格式化成用于前端显示的字符串
 * 可选的格式:
 * 'default' - 'YYYY-MM-DD HH:mm:ss'
 * 'shortDatetime' - 'YYYY-MM-DD HH:mm'
 * 'date' - 'YYYY-MM-DD'
 * 'time' - 'HH:mm:ss'
 */
export function formatDateObject (dateObject, format = "default") {
  if (!dateObject) {
    /* console.warn('toData 方法必须接受一个有效且非空的参数')*/
    return "";
  }
  let date = toDate(dateObject);
  if (date == null) {
    return "";
  }
  return fecha.format(date, format);
}

/**
 * 格式化时间
 * @param {*Date} now
 */
export function formatDate (now) {
  return formatDateObject(now, "date");
}
/**
 * 格式化剩余时间
 */
export function formatRemainingTime (start, end, format = "D天H小时M分S秒") {
  const S = 1000;
  const M = S * 60;
  const H = M * 60;
  const D = H * 24;
  let remainingTime = end - start;

  function calc (type, val) {
    let d = Math.floor(remainingTime / val);
    if (type === "D" && d <= 0) {
      format = format.replace(/D+天/, "");
    }
    let text = String(d);
    if (type !== "D") {
      text = text.padStart(2, "0");
    }
    format = format.replace(type, text);
    remainingTime = remainingTime % val;
  }
  calc("D", D);
  calc("H", H);
  calc("M", M);
  calc("S", S);
  return format;
}

/* 获取某一天及后几天0点0分0秒的时间戳
    date：为时间（某天，格式为时间戳）
    day：多少天之前或之后，number（之后为正数，之前为负数）
*/
export function dateZero (date, day) {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  let dateTime = Date.parse(date);
  if (day) {
    dateTime = dateTime + day * 1000 * 60 * 60 * 24;
  }
  return dateTime;
}
/* 获取某一天或这天的几个月之前或之后的当天的时间戳。'number'为几个月的数据
 */
export function dateMonth (date, number) {
  date = new Date(date);
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = number < 0 ? date.getDate() + 1 : date.getDate();
  let newMonth = month + number;
  let newDate = new Date(year, newMonth, day).getTime();
  return newDate;
}

/*时间戳格式处理
 *时间戳如果是10位数的话，乘以1000，得到13位正确的时间戳
 */
export function timestamp (val) {
  val = typeof val === "string" ? val : val.toString();
  val = val.length === 10 ? val * 1000 : val;
  return val;
}
/**
 * 根据生日计算年龄
 */
export function birthdayToAge (strBirthday) {
  if (!strBirthday) {
    return "";
  }
  var returnAge;
  var strBirthdayArr = formatDate(strBirthday).split("-");
  var birthYear = strBirthdayArr[0];
  var birthMonth = strBirthdayArr[1];
  var birthDay = strBirthdayArr[2];

  var d = new Date();
  var nowYear = d.getFullYear();
  var nowMonth = d.getMonth() + 1;
  var nowDay = d.getDate();

  if (nowYear === birthYear) {
    returnAge = 0; // 同年 则为0岁
  } else {
    var ageDiff = nowYear - birthYear; // 年之差
    if (ageDiff > 0) {
      if (nowMonth === birthMonth) {
        var dayDiff = nowDay - birthDay; // 日之差
        if (dayDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      } else {
        var monthDiff = nowMonth - birthMonth; // 月之差
        if (monthDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      }
    } else {
      returnAge = 0; // 返回0 表示出生日期输入错误／晚于今天
    }
  }

  return returnAge; // 返回周岁年龄
}