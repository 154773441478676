<template>
  <div :class="$style['select-picker']">
    <div @click="openPicker">
      {{ valueText || placeholderText }} <i class="cubeic-select"></i>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: Array,
    placeholderText: {
      type: String,
      default: "状态"
    },
    value: String
  },
  data () {
    return {
      valueCurrent: null,
      valueText: null
    };
  },
	watch:{
		value:{
			handler:function(){
				this.data.forEach(item => {
					if(item.value===this.value){
						this.valueText=item.text
					}
				});
			},
			immediate:true
		}
	},
  methods: {
    init () {
      this.picker = this.$createPicker({
        title: "选择状态",
        data: [this.data],
        onSelect: this.selectHandle
      });
			if(this.value){
				this.data.forEach(item => {
					if(item.value===this.value){
						this.valueText=item.text
					}
				});
			}
    },
    openPicker () {
      this.picker.show();
    },
    selectHandle (val, index, text) {
      this.valueCurrent = val[0];
      this.valueText = text[0];
      this.$emit("input", val[0]);
      this.$emit("selectHandle", val[0], index[0], text[0]);
    }
  },
  created () {
    this.init();
  }
};
</script>
<style module lang="less">
@import "~less/var.less";
.select-picker {
  color: @main-color;
}
</style>
