import * as fetch from "utils/action";
export default {
  editUserMobile(ctx, data) {
    return fetch.editUserMobile(data).then(res => {
      return res.data
    })
  },
  editUserPassword(ctx, data) {
    return fetch.editUserPassword(data).then(res => {
      return res.data
    })
  }
}