<template>
  <div id="app">
    <router-view :is="curView" />
  </div>
</template>
<script>
import AppMain from "@/layout";
import { isEmptyObj } from "formats/formate";
export default {
  data () {
    return {
      curView: null
    };
  },
  components: {
    ...AppMain
  },
  watch: {
    $route: {
      handler: function (val) {
        // 匹配视图
        let tp = this.$route.meta.view || "defaultView";
        if (tp in AppMain) {
          this.curView = tp || null;
        } else {
          console.error(
            `layout view ${tp} is unavailable ,make sure ${tp}.vue existed in src/layout and configured in src/layout/index.js`
          );
        }
        if (
          this.$route.name && this.$route.name !== "login" &&
          (!this.$store.state.currentUserMessage ||
            isEmptyObj(this.$store.state.currentUserMessage))
        ) {
          this.getUserInformation();
        }
        // }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getUserInformation () {
      this.$store
        .dispatch("getUserInformation")
        .then((res) => {})
        .catch((err) => {
          this.$toast.success(err || '获取用户信息失败')
          // setTimeout(_ => {
          //   this.getUserInformation();
          // }, 1000)
        });
    }
  }
};
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #3d3d3d;
  height: 100%;
  font-size: 15px;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
