import modules from "./modules";
import * as fetch from "utils/action";
export default {
  ...modules,
  getCode (ctx, data) {
    return fetch.getCode(data).then((res) => {
      return res;
    });
  },
  login (ctx, data) {
    return fetch.login(data).then((res) => {
      return res;
    });
  },
  getUserInformation (ctx, data) {
    return fetch.getUserInformation(data).then((res) => {
      ctx.commit("getCurrentUserMessage", res.data);
      return res.data;
    });
  }
};