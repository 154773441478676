export let trustStatus = [{
  value: "ALL",
  label: "全部"
},{
  value: "BILL_CREATED",
  label: "待审核"
},
{
  value: "BILL_CHECKED",
  label: "待回传"
},
{
  value: "BILL_BACK",
  label: "待接收"
},
{
  value: "BILL_RECEIVE",
  label: "待分派"
},
{
  value: "BILL_ASSIGN",
  label: "执行中"
},
{
  value: "BILL_EXECUTE",
  label: "待交付"
},
{
  value: "BILL_COMPLETED",
  label: "已完成"
},
{
  value: "BILL_CLOSE",
  label: "已关闭"
}
];