<template>
<pt-trust-order :statisticData="statisticData"></pt-trust-order>
</template>
<script>
import ptTrustOrder from "./index"
export default {
  components:{
    ptTrustOrder
  },
  data (){
    return {
      statisticData:{}
    }
  },
  methods:{
    getBillsStatistic (){
      this.$loading()
      this.$store.dispatch('getBillsStatistic').then(res => {
        this.statisticData = res
        this.$loading.hide()
      }).catch(err => {
        this.$loading.hide()
        this.$toast.error(err || '获取委托单统计数据失败')
      })
    }
  },
  created (){
    this.getBillsStatistic()
  }
}
</script>
<style module lang="less">
</style>