// 基于cube-ui的toast
import Vue from "vue";

Vue.mixin({
  beforeDestroy() {
    // 所有页面，在离开后，避免弹出错误信息
    // 部分请求速度较慢，此时用户返回了上一个页面，该请求仍在后台执行，并且执行相应的成功或失败回调，但应为该页面组件已经被销毁，所以很可能会抛异常toast，影响体验
    // 解决办法，在this上添加$toast属性，屏蔽Vue原型上的$toast，并将info和success方法保留下来，将error方法重置为空函数
    this.$toast = {
      info,
      success,
      message,
      error: function() {}
    };
  }
});

/* global _ */
let time = 3000; // toast默认显示时间
let toastVM = null; // 所有toast都维护在toastVM这一个变量上
let loadingVm = null;
// toast
function info(params = "", options = {}) {
  if (_.isString(params)) {
    console.log("string");
    loadingVm = null;
    toastVM = Vue.prototype.$createToast
      .call(Vue, {
        txt: params,
        icon: "iconfont icon-jingshi",
        type: "warn",
        mask: true,
        time,
        ...options
      })
      .show();
  } else {
    console.error("$toast.info第一个参数必须为字符串！");
  }
}

function message(params = "", options = {}) {
  if (_.isString(params)) {
    console.log("string");
    loadingVm = null;
    toastVM = Vue.prototype.$createToast
      .call(Vue, {
        type: "txt",
        txt: params,
        mask: true,
        time,
        ...options
      })
      .show();
  } else {
    console.error("$toast.info第一个参数必须为字符串！");
  }
}

function error(message = "", options = {}) {
  console.log("debug error", this);
  if (!_.isString(message)) {
    console.warn("$toast.error第一个参数必须为字符串！");
    message = message.toString();
  }
  loadingVm = null;
  toastVM = Vue.prototype.$createToast
    .call(Vue, {
      icon: "iconfont icon-yiwen",
      type: "error",
      mask: true,
      txt: message,
      time,
      ...options
    })
    .show();
}

function success(message = "", options = {}) {
  console.log('message', message)
  if (_.isString(message)) {
    loadingVm = null;
    toastVM = Vue.prototype.$createToast
      .call(Vue, {
        type: "correct",
        icon: "iconfont icon-chenggong",
        mask: true,
        txt: message,
        time,
        ...options
      })
      .show();
  } else {
    console.error("$toast.success第一个参数必须为字符串！");
  }
}

export function toast(message, options = {}) {
  if (_.isString(message)) {
    loadingVm = null;
    toastVM = Vue.prototype.$createToast.call(Vue, {
      type: "loading",
      icon: "iconfont iconjiazai",
      mask: true,
      txt: message,
      time,
      ...options
    });
    // try catch块内代码只是为了去掉icon，目前cube-ui还没有不带icon的toast create api，暂时用一下代码处理下
    try {
      toastVM.$el.getElementsByClassName("cube-loading")[0].remove();
      toastVM.$el.getElementsByClassName(
        "cube-toast-toastVM"
      )[0].style.marginLeft = 0;
    } catch (error) {}

    toastVM.show();
  } else {
    console.error("$toast.success第一个参数必须为字符串！");
  }
}

toast.info = info;
toast.error = error;
toast.success = success;
toast.message = message;

/**
 * 显示一个loading加载提示框
 * @param {String} message 可选
 */
export function loading(message = "", options = {}) {
  if (_.isString(message)) {
    // console.log('loadingVm', loadingVm)
    // toastVM && toastVM.remove() // 清除之前toast的实例
    // toastVM = null
    // loading.target && loading.target.hide()
    // loading.target = Vue.prototype.$createToast.call(Vue, {
    //   txt: message,
    //   type: 'loading',
    //   mask: true,
    //   time: 0, // 设置为0，不自动关闭
    //   ...options
    // })

    // loading.target.show()
    // // 提供两个方法关闭loading
    // loading.close = function () {
    //   this.target.hide()
    // }
    // loading.hide = loading.close

    loadingVm && loadingVm.remove(); // 清除之前loading的实例
    loadingVm = Vue.prototype.$createToast.call(Vue, {
      txt: message,
      type: "loading",
      mask: true,
      time: 0, // 设置为0，不自动关闭
      ...options
    });

    loadingVm.show();
    // 提供两个方法关闭loading
    loading.close = function() {
      loadingVm && loadingVm.hide();
    };
    loading.hide = loading.close;
  } else {
    console.error("$loading第一个参数必须为字符串！");
  }
}

Vue.prototype.$toast = toast;

Vue.prototype.$loading = loading;

// 使用方式：

// this.$toast()
// this.$toast('message')
// this.$toast.info('message')
// this.$toast.error('message')
// this.$toast.success('message')

// this.$loading()
// this.$loading('message')