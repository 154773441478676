<template>
    <div :class="classes" ref="scroller" @scroll="scrollHandler" @touchstart="touchstartHandler" @touchmove="touchmoveHandler">
        <slot />
    </div>
</template>

<script>
// 组件class前缀
// 本组件可提取成指令，用来阻止android或者ios设备浏览器上上拉或下拉露底的情况
const prefixCls = 'wt-native-scroller'

export default {
  name: 'wt-native-scroller',
  computed: {
    classes () {
      return `${prefixCls}`
    },
    scroller () {
      return this.$refs.scroller
    }
  },
  methods: {
    scrollHandler (e) {
      let { height } = e.target.getBoundingClientRect()
      let maxScrollDist = Math.floor(e.target.scrollHeight - height)
      if (e.target.scrollTop >= maxScrollDist) {
        this._start = null
      }
    },
    touchstartHandler (e) {
      this._start = e.touches[0]
    },
    touchmoveHandler (e) {
      const scrollBox = this.scroller
      if (this._start) {
        const startY = this._start.pageY
        const moveY = e.touches[0].pageY
        const top = scrollBox.scrollTop
        const ch = scrollBox.clientHeight
        const sh = scrollBox.scrollHeight
        if ((top === 0 && moveY > startY) || (top + ch === sh && moveY < startY)) {
          e.preventDefault()
        }
      } else {
        e.preventDefault()
      }
    }
  },
  mounted () {
    // this.waterfall.style = `
    //   column-count
    // `
  }
}
</script>

<style lang="less">
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
.wt-native-scroller{
  height:100%;
  overflow:auto;
}
</style>
