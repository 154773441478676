// 引入所需全局组件
import Vue from "vue";

import { View, Avatar, Grid, CountDownBox, Box, Textarea } from "wt-ui";
import { FormItem } from "wt-ui/lib/pc";
import { Tabbar, TabbarItem, Loadmore, DatePicker } from "wt-ui/lib/mobile";
import {
  /* eslint-disable no-unused-vars */
  Style,
  createAPI,
  Tip,
  Toast,
  Slide,
  Scroll,
  RecycleList,
  Sticky,
  Checkbox,
  TabBar,
  ScrollNavBar,
  Dialog,
  ImagePreview,
  TabPanels,
  Radio,
  Checker,
  CheckboxGroup,
  Select,
  actionSheet,
  Button,
  Upload
} from "cube-ui";

import ptInput from "cps/base/pt-input";
import ptFormItem from "cps/base/pt-form-item";
import wtButton from "cps/base/pt-button";
import ptSearch from "cps/base/pt-search";
import wtSelect from 'cps/base/wt-select'
import selectPicker from "cps/base/select-picker";
import wtNativeScroller from 'cps/base/wt-native-scroller'
import tabBar from "cps/base/tab-bar"
import ptArrayInput from "cps/base/pt-array-input"
import ptDatePicker from "cps/base/pt-date-picker"


import listHeader from "cps/page-base/list-header";
import statisticsData from "cps/page-base/statistics-data";
import statusTag from "cps/page-base/status-tag"
import detailHeader from "cps/page-base/detail-header";
import taskDetailHeader from "cps/page-base/task-detail-header";
// cube-ui
Vue.use(Toast);
Vue.use(Slide);
Vue.use(RecycleList);
Vue.use(Sticky);
Vue.use(Checkbox);
Vue.use(TabBar);
Vue.use(ScrollNavBar);
Vue.use(Scroll);
Vue.use(Dialog);
Vue.use(ImagePreview);
Vue.use(TabPanels);
Vue.use(Radio);
Vue.use(Checker);
Vue.use(CheckboxGroup);
Vue.use(Textarea);
Vue.use(Select);
Vue.use(actionSheet);
Vue.use(Button);
Vue.use(Tip);
Vue.use(Upload);


Vue.use(View);
Vue.use(Avatar);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Grid);
Vue.use(Box);
Vue.use(CountDownBox);
Vue.use(FormItem);
Vue.use(Loadmore)
Vue.use(DatePicker)
  //base
Vue.component("wt-input", ptInput);
Vue.component("pt-form-item", ptFormItem);
Vue.component("wt-button", wtButton);
Vue.component("select-picker", selectPicker);
Vue.component("pt-search", ptSearch);
Vue.component("wt-native-scroller", wtNativeScroller);
Vue.component("pt-array-input", ptArrayInput);
Vue.component("wt-select", wtSelect)
Vue.component("pt-date-picker", ptDatePicker)

//page-base
Vue.component("list-header", listHeader);
Vue.component("statistics-data", statisticsData);
Vue.component('status-tag', statusTag)
Vue.component('detail-header', detailHeader)
Vue.component('tab-bar', tabBar)
Vue.component('task-detail-header', taskDetailHeader)