import * as fetch from "utils/action";
import sampleData from "../datas/sample"
import { builderLoadMoreData } from "formats/formate.js"
export default {
  getSampleList(ctx, data) {
    return fetch.getSampleList(data).then(res => {
      res = builderLoadMoreData(res)
      res.data.forEach(item => {
        item = sampleData.getSampleData(item)
      });
      return res
    })
  },
  getSampleDetail(ctx, id) {
    return fetch.getSampleDetail(id).then(res => {
      let result = res.data
      result.order = sampleData.getSampleData(result.order)
      return result
    })
  },
  geneOrderStatusUpdate(ctx, data) {
    return fetch.geneOrderStatusUpdate(data).then(res => {
      return res
    })
  },
  getAbnormalReason(ctx, data) {
    return fetch.getAbnormalReason(data).then(res => {
      return res.data
    })
  }
}