export let futureActionAcceptableResults = [
  // mcl
  { key: 'RE_PROVIDE', value: '重新提供样品' },
  { key: 'STOP', value: '中止' },
  { key: 'ABANDON', value: '废弃' },
  { key: 'RE_SET', value: '恢复' },
  { key: 'QUE_REN_YI_LING_YANG', value: '确认已领样' },
  { key: 'QUE_REN_YI_TI_JIAO_SHU_JU', value: '确认已提交原始记录' },
  { key: 'FU_CE', value: '复测' }
]
export let detectionCategory = [
  { key: 'COMMON_DISEASES', value: '常见疾病' },
  { key: 'GENETIC_DISEASES', value: '高危遗传病' },
  { key: 'RARE_DISEASES', value: '罕见病' },
  { key: 'PHARMACOGENOMICS', value: '药物基因组' },
  { key: 'LIFE_STYLE', value: '生活方式' },
  { key: 'ANCESTRY', value: '祖源' }
]
export let categoryType = [
  { key: 'COMMON_DISEASES', value: '疾病' },
  { key: 'GENETIC_DISEASES', value: '高危遗传病' },
  { key: 'RARE_DISEASES', value: '罕见病' },
  { key: 'PHARMACOGENOMICS', value: '药物成分' },
  { key: 'LIFE_STYLE', value: '生活方式' }
]
export let detectionType = [
  { key: 'cancer', value: 'COMMON_DISEASES' },
  { key: 'life-style', value: 'LIFE_STYLE' },
  { key: 'medicine', value: 'PHARMACOGENOMICS' },
  { key: 'high-risk', value: 'GENETIC_DISEASES' },
  { key: 'rare-disease', value: 'RARE_DISEASES' }
]
export let ConfigType = [
  { key: 'RISK', value: '易感模型' },
  { key: 'CAUSAL', value: '致病模型' },
  { key: 'LH', value: '乐活模型' },
  { key: 'CAUSAL_AUTO', value: '致病模型(自动)' },
  { key: 'MT_HAPLO_TYPE', value: '母系祖源单倍型' },
  { key: 'Y_HAPLO_TYPE', value: '父系祖源单倍型' },
  { key: 'ANCESTRY_COMPOSITION', value: '祖源成分' },
  { key: 'NEANDERTHAL', value: 'Neanderthal比例' }
]
export let ConfigSex = [
  { key: 'M', value: '男性专用' },
  { key: 'F', value: '女性专用' },
  { key: 'MF', value: '男女通用' }
]
export let detectionChineseType = [
  { key: '疾病', value: '疾病' },
  { key: '药物成分', value: '药物成分' },
  { key: '生活方式', value: '生活方式' },
  { key: '高危遗传病', value: '高危遗传病' },
  { key: '罕见病', value: '罕见病' },
  { key: '祖源', value: '祖源' }
]
// export let operationLog = [
//   { key: 'WAITING_SAMPLE_RECOLLECT', value: '确认样本重采' },
//   { key: 'ORDER_CLOSE', value: '操作了关闭订单' },
//   { key: 'NOT_QUALIFIED_WAIT_FUTURE_ACTION', value: '操作了不合格' },
//   { key: 'AGENT_WAITING_SAMPLE_RECOLLECT', value: '确认样本重采' },
//   { key: 'RISK_TEST', value: '操作了风险上机' }
// ]
export let operationLog = futureActionAcceptableResults
export let packageProductTypes = [
  { key: 'PROPRIETARY', value: '中源协和计算' },
  { key: 'THIRDPARTY', value: '第三方出报告' }
]

export let terrace = [{
  label: '纸质',
  value: 'PAPER'
},
{
  label: '电子版数据',
  value: 'DATA'
}
// {
//   label: '移动端',
//   value: 'MOBILE'
// },
// {
//   label: '电子版（PDF）',
//   value: 'ELECTRONIC'
// }
]
export let ucasTerrace = [{
  label: '数据',
  value: 'DATA'
}]
export let terraceType = [{
  value: '纸质',
  label: 'PAPER'
},
{
  value: '数据',
  label: 'DATA'
},
{
  value: '移动端',
  label: 'MOBILE'
},
{
  value: '电子版（PDF）',
  label: 'ELECTRONIC'
}
]
export let certificatesTypeMap = {
  'CHINESE_IDENTITY_CARD': '身份证',
  'MILITARY_ID': '军官证',
  'PASSPORT': '护照'
}
export let gender = [
  { key: 'F', value: '仅适用于女性' },
  { key: 'M', value: '仅适用于男性' },
  { key: 'MF', value: '适用于男性和女性' }
]
export let contractStatus = [
  { key: 'INEFFECTIVE', value: '未生效' },
  { key: 'ACTIVITED', value: '已生效' },
  { key: 'EXPIRED', value: '已失效' },
  { key: 'TERMINATE', value: '已终止' }
]
export let dayType = [
  { value: 'WORK_DAY', label: '工作日' },
  { value: 'NATURAL_DAY', label: '自然日' }
]
export let sexMapEnglish = {
  0: 'FEMALE',
  1: 'MALE'
}
export let nameMapEnglish = [
  { key: 'FEMALE', value: '女' },
  { key: 'MALE', value: '男' }
]
export let labReceiveResults = [
  { value: '合格', key: 'QUALIFIED_CREATE_NEW_SAMPLE_BOARD' },
  { value: '合格', key: 'QUALIFIED_USE_EXIST_SAMPLE_BOARD' },
  { value: '合格', key: 'QUALIFIED_MANUAL_PROCESSING' }
]
export let dyeAndScanResults = [
  { value: '合格', key: 'DYE_AND_SCAN_COMPLETE' },
  { value: '不合格', key: 'DYE_AND_SCAN_ABNORMAL' }
]
export let orderStatusResults = [
  { value: '合格', key: 'QUALIFIED' },
  { value: '不合格', key: 'NOT_QUALIFIED_WAIT_FUTURE_ACTION' },
  { value: '风险上机', key: 'RISK_WAIT_FUTURE_ACTION' },
  { value: '重新上机', key: 'RETEST' },
  { value: '完成', key: 'SAMPLE_QUALIFIED' },
  { value: '样本重采', key: 'SAMPLE_RECOLLECT' },
  { value: '数据合格', key: 'REPORT_GENERATE' },
  { value: '突变验证', key: 'MUTATION_VERIFICATION' },
  { value: '不合格上机', key: 'NOT_QUALIFIED_RISK_TEST' },
  { value: '异常', key: 'HAS_ERROR_WAIT_FUTURE_ACTION' },
  { value: '释放排版', key: 'RELEASE_CHIP_SLOT' },
  ...futureActionAcceptableResults,
  ...labReceiveResults,
  ...dyeAndScanResults
]
export let holidayType = [{
  label: '法定节假日',
  value: 'LEGAL_HOLIDAYS'
}, {
  label: '调休',
  value: 'LEAVE_IN_LIEU'
}, {
  label: '补班',
  value: 'MAKE_UP_FOR_WORK'
}]
export let collectorType = [
  { key: 'BLOOD_CARD', value: '血卡' },
  { key: 'ORAL_SWAB', value: '口腔拭子' },
  { key: 'SALIVA', value: '唾液' },
  { key: 'VENOUS_BLOOD', value: '全血(静脉血)' },
  { key: 'VENOUS_BLOOD_STRECK', value: '全血(静脉血)' }
]
export let systemDetermine = [
  { key: 'QUALIFIED', value: '合格' },
  { key: 'NOT_QUALIFIED_WAIT_FUTURE_ACTION', value: '不合格' },
  { key: 'RISK_WAIT_FUTURE_ACTION', value: '风险上机' },
  { key: 'REPORT_GENERATE', value: '出具报告' },
  { key: 'NOT_QUALIFIED_WAIT_FUTURE_ACTION', value: '不合格,等待后续处理' }
]
export let dataAnalysisedSystemDetermine = [
  { key: 'QUALIFIED', value: '合格' },
  { key: 'NOT_QUALIFIED_WAIT_FUTURE_ACTION', value: '不合格' },
  { key: 'RISK_WAIT_FUTURE_ACTION', value: '风险上机' },
  { key: 'REPORT_GENERATE', value: '合格' }
]

export const sampleStatusList = [{
  value: 'CREATED',
  label: '待接收'
},
{
  value: 'ORDER_RECEIVED',
  label: '待分派'
},
{
  value: 'ORDER_ASSIGN',
  label: '待领样'
},
{
  value: 'ORDER_GET',
  label: '待检测'
},
{
  value: 'ORDER_EXAM',
  label: '待报告'
},
{
  value: 'REPORT_CREATE',
  label: '待审核'
},
{
  value: 'REPORT_CHECKED',
  label: '待归还'
},
{
  value: 'COMPLETED',
  label: '已完成'
},
{
  value: 'CLOSED',
  label: '已关闭'
}
]
export const departementList = [{
  value: '医药分析室',
  label: '医药分析室'
}, {
  value: '材料分析室',
  label: '材料分析室'
}, {
  value: '综合保障室',
  label: '综合保障室'
}]
export function cycleChange (val) {
  if (!val) {
    return ''
  }
  return val.slice(1, -1)
}