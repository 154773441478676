import * as fetch from "utils/action";
import { builderLoadMoreData } from "formats/formate.js"
import billsData from "../datas/bills"
export default {
  getBillList (ctx, data) {
    return fetch.getBillList(data).then(res => {
      res = builderLoadMoreData(res)
      res.data.forEach(item => {
        item = billsData.getBillData(item)
      });
      return res
    })
  },
  getBillDetail (ctx, id){
    return fetch.getBillDetail(id).then(res => {
      //  res.data=billsData.getBillData(res.data)
      return billsData.formatBillDetail(res.data)
      // return res.data
    })
  },
  updateBillStatus(ctx, data) {
    return fetch.updateBillStatus(data).then(res => {
      return res
    })
  },
  getBillsStatistic(ctx){
    return fetch.getBillsStatistic().then(res=>{
      return res.data
    })
  },
  assginPre(ctx, data) {
    return fetch.assginPre(data).then(res => {
      return res.data
    })
  },
  systemCustomerList (ctx, data) {
    return fetch.systemCustomerList(data).then(res => {
      return res.data
    })
  },
  getOrderCountByBill(ctx, data) {
    return fetch.getOrderCountByBill(data).then(res => {
      return res.data
    })
  },
  handleSettle(ctx, data) {
    data.operType = 'SETTLEMENT'
    return fetch.billOtherUpdate(data).then(res => {
      return res.data
    })
  },
  handleInvoice(ctx, data) {
    data.operType = 'INVOICE'
    return fetch.billOtherUpdate(data).then(res => {
      return res.data
    })
  },
  handleDelay(ctx, data) {
    data.operType = 'DELAY'
    return fetch.billOtherUpdate(data).then(res => {
      return res.data
    })
  },
  callNotice(ctx, data) {
    return fetch.callNotice(data).then(res => {
      return res
    })
  },
}