<template>
	<div :class="$style['tab-bar']">
		<cube-tab-bar v-model="valueCrrent">
      <cube-tab v-for="(item) in options" :key="item[props.value]" :label="item[props.label]" :value="item.value">
			</cube-tab>
		</cube-tab-bar>
	</div>
</template>
<script>
export default {
  props:{
    options:{
      type:Array,
      default:function (){
        return []
      }
    },
    value:String,
    props:{
      type:Object,
      default:function (){
        return {
          label:'label',
          value:'value',
          action:'action'
        }
      }
    },
    isRouter:Boolean//页面是否根据选项变换跳转
  },
  data (){
    return{
      valueCrrent:this.value
    }
  },
  watch:{
    valueCrrent:{
      handler:function (val){
        this.$emit('input', val)
      }
    },
    value (val){
      this.valueCrrent = val
    }
  }
}
</script>
<style module lang="less">
@import "~less/var.less";
.tab-bar{
	:global{
		.cube-tab-bar{
			border:solid 1px @main-color;
			border-radius: 5px;
			i{
				display: none
			}
			.cube-tab{
				color: @main-color;
			}
			.cube-tab_active{
				color: #fff;
				background-color: @main-color;
			}
		}
	}
}
</style>