<template>
  <cube-button
    :class="[classes,danger && 'wt-button-danger' ]"
    :light="light"
    :inline="inline"
    :outline="outline"
    :primary="primary"
    :icon="icon"
    :disabled="disabled"
    :active="active"
    :type="type"
    :style="style"
    @click="handleClick"
  >
    <slot>button</slot>
  </cube-button>
</template>

<script>
import Vue from "vue";
import { Button } from "cube-ui";
Vue.use(Button);
// 组件class前缀
const prefixCls = "wt-button";

export default {
  name: "wt-button",
  props: {
    // 亮色
    light: {
      type: Boolean,
      default: false
    },
    // 是否内联
    inline: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    // 主题色
    primary: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    // [button/submit]
    type: {
      type: String,
      default: "button"
    },
    radius: {
      type: String,
      default: "30px"
    },
    danger: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes () {
      if (this.disabled) {
        return [`${prefixCls}__disabled`, prefixCls];
      }
      return `${prefixCls}`;
    },
    style () {
      return `border-radius:${this.radius}`;
    }
  },
  methods:{
    handleClick(){
      this.$emit('click')
    }
  }
};
</script>

<style lang="less">
@import "~less/platform.less";
@import "~less/var.less";
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
.wt-button {
  padding-top: 10px;
  padding-bottom: 8px;
  &__disabled {
    pointer-events: none;
  }
}
.cube-btn-primary{
  background: @main-color !important;
}
.cube-btn-primary.cube-btn_disabled{
  background:@disabled-btn-color !important;
}
.wt-button-danger{
  color:@intensify-color;
  border: 1px solid @intensify-color;
}
.wt-button-danger::after{
  display: none !important;
}
@supports (@ios) {
  .wt-button {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
</style>
