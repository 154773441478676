import getEnumeration from "formats/enumeration"
import { taskStatusList } from "json/task-options"
import { formatDateObject, formatDate } from '@/utils/formatUtils/date.js'
import { terrace } from 'json/lab-order'
export default {
  getTaskData(data) {
    data.status_text = data.status && getEnumeration(data.status, taskStatusList)
    let startTime = data.startTime && formatDateObject(data.startTime, 'dateSlash')
    let endTime = data.endTime && formatDateObject(data.endTime, 'dateSlash')
    data.term = startTime + '-' + (endTime ? endTime : '')
    data.delivery_type = data.deliveryType ? data.deliveryType.map(e => getEnumeration(e, terrace)).toString() : ''
    return data
  },
  formatTaskDetail(ele) {
    ele.create_time = formatDate(ele.createTime, 'date')
    ele.start_time = formatDate(ele.startTime, 'date')
    ele.end_time = formatDate(ele.endTime, 'date')
    ele.status_text = ele.status === 'NO_ORDER' ? '无样品' : getEnumeration(ele.status, taskStatusList)
    ele.delivery_type = ele.deliveryType ? ele.deliveryType.map(e => getEnumeration(e, terrace)).toString() : ''
    return ele
  }
}