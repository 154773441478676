import state from "../../store/state"
// 判断是否有委托单权限
export function hasPermissionsOfTrust() {
  let userMessage = state.currentUserMessage
  if (userMessage && userMessage.roles) {
    let hasTrustArr = ['ROLE_DIRECTOR', 'ROLE_ST', 'ROLE_ADMIN'] //只有这三种角色可以查看，使用委托单
    let arr = userMessage.roles.filter(item => {
      return hasTrustArr.includes(item)
    })
    return !!arr.length
  }
  return false
}