<template>
<div :style="{background:backgroundColor,color:color}" :class="$style['status-tag']">
	{{text}}
</div>
</template>
<script>
export default {
  props:{
    backgroundColor:String,
    color:{
      type:String,
      default:'#fff'
    },
    text:{
      type:String,
      default:"加急"
    }
  }
}
</script>
<style lang="less" module>
@import "~less/var.less";
.status-tag{
	border-radius:20px;
	padding:3px 10px;
	font-size:@font-size-tip
}
</style>