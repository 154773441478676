export default [{
  path: '/task-order',
  name: 'task-order',
  component: resolve => require(['views/task-order'], resolve),
  meta: { view: 'tabbar', auth: true }
} ,{
  path: '/task-order-detail/:id',
  name: 'task-order-detail',
  props:true,
  component: resolve => require(['views/task-order-detail'], resolve),
  meta: { view: 'tabbar', auth:true }
},   {
  path: '/task-reassign/:id/:billId',
  name: 'task-reassign',
  props:true,
  component: resolve => require(['views/task-reassign'], resolve),
  meta: { view: 'defaultView', auth:true }
}]