export let sampleStatusList = [{
    name: 'LAB_SAMPLE_RETURNED',
    description: '样本重采',
    operation: '样本重采',
    icon: ''
  },
  {
    name: 'CREATED',
    description: '创建样品',
    operation: '创建样品',
    completeText: '创建样品'
  },
  {
    name: 'ORDER_RECEIVED',
    description: '待接收',
    operation: '待接收',
    completeText: '已接收'
  },
  {
    name: 'ORDER_ASSIGN',
    description: '待分派',
    operation: '待分派',
    completeText: '已分派'
  },
  {
    name: 'ORDER_GET',
    description: '待领样',
    operation: '待领样',
    completeText: '已领样'
  },
  {
    name: 'ORDER_EXAM',
    description: '检测中',
    operation: '检测中',
    completeText: '已检测',
    icon: 'icon-yangpinjiancezhong',
    type: 'lab'
  },
  {
    name: 'REPORT_CREATE',
    description: '待报告',
    operation: '待报告',
    completeText: '已报告'
  },
  {
    name: 'REPORT_CHECKED',
    description: '待审核',
    operation: '待审核',
    completeText: '已审核'
  },
  {
    name: 'ORDER_BACK',
    description: '待归还',
    operation: '待归还',
    completeText: '已归还'
  },
  {
    name: 'COMPLETED',
    description: '已完成',
    operation: '已完成',
    completeText: '已完成'
  },
  {
    name: 'CLOSED',
    description: '已关闭',
    operation: '已关闭',
    completeText: '已关闭'
  }
]