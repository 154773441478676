import request from "./request";
// 获取登录验证码
export function getCode(data) {
  return request(`/uaa/api/public/sendBusinessAuthorizeCode`, "POST", data);
}
// 登录
export function login(data) {
  return request("/auth/login", "POST", data);
}
//获取用户信息
export function getUserInformation() {
  return request("/uaa/api/organization-user/information", "GET");
}
//获取委托单列表
export function getBillList(data) {
  return request(`/lab/api/bills/by-example`, "POST", data);
}
//获取样本列表
export function getSampleList(data) {
  return request(`/lab/api/orders/by-example`, 'POST', data)
}
//获取委托单详情
export function getBillDetail(id) {
  return request(`/lab/api/bills/detail/${id}`, 'GET')
}
//获取样本详情
export function getSampleDetail(id) {
  return request(`/lab/api/order/details/${id}`, 'GET')
}
// 委托单状态更新
export function updateBillStatus(data) {
  return request(`/lab/api/workflow/bill-status-update`, 'POST', data)
}
//委托单统计
export function getBillsStatistic() {
  return request(`/lab/api/bills/statistic`, 'GET')
}
//更新样本状态
export function geneOrderStatusUpdate(data) {
  return request(`/lab/api/workflow/order-batch-status-update`, 'POST', data)
}
// 获取实验室流程中异常原因（样本）
export function getAbnormalReason(type) {
  return request(`lab/api/enumeration/${type}`, 'GET')
}
// 任务分派预选保存
export function assginPre(data) {
  return request(`/lab/api/bill/assgin-pre/save`, 'POST', data)
}
// 系统用户列表
export function systemCustomerList(data) {
  return request('/uaa/api/organization-user-admin/roles', 'POST', data)
}
// 获取委托单中样品总数以及委托单相关信息（用于任务分派页面）
export function getOrderCountByBill(billId) {
  return request(`/lab/api/bill/assgin-pre/${billId}`, 'GET')
}
// 结算
export function billOtherUpdate(data) {
  return request('/lab/api/bills/update', 'POST', data)
}
//获取任务单列表
export function getTaskList(data) {
  return request(`/lab/api/production-tasks/by-example`, 'POST', data)
}
//生产任务统计
export function getTaskStatistic() {
  return request(`/lab/api/production-tasks/statistic`, 'GET')
}
// 委托单催缴
export function callNotice(data) {
  return request('/lab/api/bills/call-notice', 'POST', data)
}
// 获取生产任务单详情
export function getProductionTasksDetail(id) {
  return request(`/lab/api/production-tasks/details/${id}`, 'GET')
}
// 任务改派
export function taskReassgin(data) {
  return request(`/lab/api/production-tasks/reassignment`, 'POST', data)
}
//修改用户手机号码
export function editUserMobile(data) {
  return request(`/uaa/api/user/newMobile`, 'POST', data)
}
//修改用户密码
export function editUserPassword(data) {
  return request(`/uaa/api/user/modify-password`, 'POST', data)
}