<template>
 <div :class="$style['pt-array-input']">
    <div v-for="(item,i) in Value" :key="i" style="margin-bottom:10px;">
      <div :class="$style['item-box']">
        <wt-input :placeholder="placeholder" v-model="item.value" @mousedown.stop.native @click.stop.native :class="'courier_'+i"></wt-input>
        <i class="iconfont icon-shanchu" style="margin-left:10px" @click="deleteItem(i)"></i>
      </div>
      <span :class="$style['add-btn']" @click="addItem(i)" v-show="i==Value.length-1">添加一条</span>
    </div>
    
 </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default:()=>[{value:''}]
    },
    placeholder:{
      type:String,
      default:'请填写'
    }
  },
  data () {
    return {
      Value: this.value ? this.value : [{value:''}],
    }
  },
  watch: {
    value (val) {
      this.Value = val
    },
    Value:{
      handler:function(val){
        this.$emit('input', val)
      },
      deep:true
    }
  },
  methods: {
    addItem (index) {
      if (this.Value[index].value === '') {
        this.$toast.info('请填写快递单号！')
        return
      }
      this.Value.push({value:''})
      this.$nextTick(() => {
        document.querySelector('.courier_' + (index + 1) + '>input').focus()
      })
    },
    deleteItem (index) {
      if (index === 0 && this.Value.length === 1) {
        this.$toast.error('不能再删除了')
        return
      }
      this.$confirm('确定要删除该条数据?').then(_ => {
        this.Value.splice(index, 1)
      })
    },
  }
}
</script>

<style module lang="less">
@import "~less/var.less";
.pt-array-input{
  text-align: left;
  width: 100%;
  .add-btn{
    display: inline-block;
    color: @main-color;
    margin-top: 10px;
    margin-left: 20px;
  }
}
.item-box{
  display: flex;
  align-items: center;
  :global{
    .wt-input{
      flex: 1;
    }
  }
  
}
</style>
