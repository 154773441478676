export default [{
  path: '/sample-detail/:id',
  name: 'sample-detail',
  props: true,
  component: resolve => require(['views/sample-detail'], resolve),
  meta: { view: 'defaultView', auth: true }
},
{
  path: '/sample-status/:status/:id',
  name: 'sample-status',
  props: true,
  component: resolve => require(['views/sample-status'], resolve),
  meta: { view: 'defaultView', auth: true }
}
]