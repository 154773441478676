<template>
  <wt-input
    type="search"
    :class="classes"
    :clearable="false"
    v-bind="$attrs"
    v-on="$listeners"
		v-model="searchKey"
    :placeholder="placeholder" @keydown.enter="search"
  >
    <i class="cubeic-search" v-if="showIcon" slot="lt"></i>
  </wt-input>
</template>

<script>
// 组件class前缀
const prefixCls = "wt-search";

export default {
  name: "wt-search",
  computed: {
    classes () {
      return `${prefixCls}`;
    }
	},
	props:{
		placeholder:{
			type:String,
			default:'请输入项目名称进行搜索'
		}
	},
  data () {
    return {
			showIcon: true,
			searchKey:null
    };
	},
	watch:{
		searchKey(val){
			this.$emit('input',val)
		}
	},
  methods: {
    focusHandler () {
      this.showIcon = false
    },
    blurHandler () {
      this.showIcon = true
		},
  }
};
</script>

<style lang="less">
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
@import "~less/var.less";
.wt-search {
  padding: 0.24rem;
  border-radius: 1rem;
  &__lt {
    width: 1.4em;
  }
  .cube-input-prepend {
    margin: 0 0.1em;
    color: @font-of-grey;
  }
}
.wt-search__lt {
  width: 1.4em;
}
</style>
