<template>
<pt-tabbar class="pt-tabbar__withData" :data="config"></pt-tabbar>
</template>

<script>
import { mapGetters } from 'vuex'
import PtTabbar from './index'
const config = [
  {
    label: '委托单',
    to: '/Home',
    icon: {
      active: 'iconfont icon-weituodan icon-active',
      normal: 'iconfont icon-weituodan'
    }
  },
  {
    label: '任务单',
    to: '/task-order',
    icon: {
      active: 'iconfont icon-renwudan icon-active',
      normal: 'iconfont icon-renwudan'
    }
  }
]
export default {
  name: 'pt-tabbar__withData',
  components: {
    'pt-tabbar': PtTabbar
  },
  data () {
    return {
      config:config
    }
  },
  methods: {
  },
  computed: {
  },
  watch: {
  }
}
</script>
