// 枚举查询。传入数组和值
// returnLabel是返回的字段名
export default function getEnumeration (
  val,
  Arr,
  returnLabel = "label",
  returnValue = "value"
) {
  let label;
  if (val && Arr && Arr.length) {
    Arr.forEach((item) => {
      if (item[returnValue] === val) {
        label = item[returnLabel];
      }
    });
    return label;
  } else {
    console.error("枚举和值是必传数据，请正确传入参数");
  }
}
