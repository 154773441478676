export function join (...urls) {
  return urls
    .map((url) => {
      return url && url.replace(/(^\/)|(\/$)/g, "");
    })
    .join("/");
}

export default {
  join
};
