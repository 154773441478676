<template>
  <div :class="$style['pt-trust-order']">
    <list-header class="list-header" v-model="params.searchKey" searchPlaceholder="请输入委托单编号或客户名称搜索"></list-header>
    <div class="trust-statistics-all">
      <statistics-data class="trust-statistics" :data="statisticData"></statistics-data>
			<div class="trust-list-message">
      <div class="trust-list-title">
        <div class="title">委托单</div>
        <select-picker v-model="params.billStatusEnum" :data="filterData"></select-picker>
      </div>
      <trust-list :params="params"></trust-list>
			</div>
    </div>
  </div>
</template>
<script>
import { trustStatus } from "json/bill-options";
import trustList from "./trust-list/withData";
export default {
  props:{
    statisticData:{
      type:Object,
      default:function (){
        return {}
      }
    }
  },
  data () {
    return {
      options: trustStatus,
      params:{}
    };
  },
  components: {
    trustList
  },
  computed: {
    filterData () {
      return trustStatus.map((item) => ({
        text: item.label,
        value: item.value
      }));
    }
  },
  methods: {
  }
};
</script>
<style module lang="less">
@import "~less/var.less";
.pt-trust-order {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow-y: hidden;
  :global {
		.list-header{
			display: flex;
		}
    .trust-statistics {
			margin-top: 40px;
			display: flex;
			justify-content: space-around
		}
		.trust-list-message{
			display: flex;
			flex-direction: column;
			flex: 1;
			overflow-y: hidden
		}
    .trust-statistics-all {
			padding: 0 @page-padding;
			display: flex;
			flex-direction: column;
			flex: 1;
			overflow-y: hidden
    }
    .trust-list-title {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      .title {
        font-weight: 600;
      }
    }
  }
}
.filter {
}
</style>
