export let taskStatusList = [{
  value: 'ALL',
  label: '全部'
},{
  value: 'PRODUCTION_TASK_CREATED',
  label: '待领样'
},
{
  value: 'PRODUCTION_TASK_GET',
  label: '待检测'
},
{
  value: 'PRODUCTION_TASK_EXAM',
  label: '待报告'
},
{
  value: 'PRODUCTION_TASK_REPORT',
  label: '待审核'
},
{
  value: 'PRODUCTION_TASK_CHECK',
  label: '待归还'
},
{
  value: 'PRODUCTION_TASK_COMPLETED',
  label: '已完成'
},
{
  value: 'PRODUCTION_TASK_CLOSE',
  label: '已关闭'
}
]