import { ossUploadBigFile, getOssSignature } from "../action";
import _ from "formats/typeof";

export async function uploadBigFile (
  resourceConfig,
  file,
  fileKey = "file",
  api = ossUploadBigFile,
  addTime = true,
  isPublic = false
) {
  let params = {
    ...resourceConfig,
    type: isPublic ? "PUBLIC_RESOURCE" : "PRIVATE_RESOURCE"
  };
  if (!_.isFile(file)) {
    throw new Error(
      `uploadBigFile（or this.$upload）方法参数file并非一个有效的文件`
    );
  }
  let response = await api(params);
  console.log("response", response.data);
  let fullPath = addTime
    ? response.data.dir + "/" + new Date().getTime() + "-" + file.name
    : response.data.dir + "/" + file.name;
  let config = {
    key: fullPath,
    policy: response.data.policy,
    Signature: response.data.signature,
    OSSAccessKeyId: response.data.accessid,
    file
  };
  let uploadRes = await getOssSignature(response.data.host, config);
  if (uploadRes.status === 204) {
    console.log("Uploade file", fullPath);
    return fullPath;
  } else {
    return null;
  }
}
