import * as fetch from "utils/action";
import { builderLoadMoreData } from "formats/formate.js"
import taskData from "../datas/task"
import billsData from "../datas/bills"
export default {
  getTaskList (ctx, data) {
    return fetch.getTaskList(data).then(res => {
      res = builderLoadMoreData(res)
      res.data.forEach(item => {
        item = taskData.getTaskData(item)
      });
      return res
    })
  },
  getTaskStatistic (ctx) {
    return fetch.getTaskStatistic().then(res => {
      return res.data
    })
  },
  getProductionTasksDetail(ctx, id) {
    return fetch.getProductionTasksDetail(id).then(res => {
      let { bill, productionTask, status } = res.data
      if (bill) bill = billsData.formatBillDetail(bill)
      productionTask = taskData.formatTaskDetail(productionTask)
        // logs = logs?.sort(compare('operationTime'))
        // // logs.forEach(e => {
        // //   e.orderStatusResult = formatOperationLog(e.orderStatusResult)
        // // })

      // let task = null
      // // 将tasks写到最新状态的childStatus字段内
      // /* global _ */
      // if (_.isArray(tasks) && tasks.length > 0) {
      //   task = tasks[0]
      // }
      return {
        productionTask,
        status,
        bill
      }
    })
  },
  taskReassgin(ctx, data) {
    return fetch.taskReassgin(data).then(res => {
      return res.data
    })
  },
}