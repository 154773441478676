import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import bill from "./modules/bill"
import sample from "./modules/sample"
import task from "./modules/task"
import setting from "./modules/setting"
Vue.use(VueRouter);
const routes = [
  ...bill,
  ...sample,
  ...task,
  ...setting,
  {
    path: "/",
    redirect: '/Home'
  },
  {
    path: "/Home",
    name: "Home",
    component: Home,
    meta: { view: "tabbar" }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import (/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/login",
    name: "login",
    component: (resolve) => require(["views/login"], resolve),
    meta: { view: "defaultView" }
  }
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;