<template>
  <div :class="$style['header-message']">
    <div :class="$style['list-header-banner']">
      <div class="left-logo-message">
        <img :src="require('@/assets/logo-name.png')" />
      </div>
      <div class="right-user-message" @click="toSetting">
        <img src="" />
        {{ currentUserMessage.firstName }}
      </div>
    </div>
    <div class="search">
      <pt-search  v-model="searchKey" @keydown.enter.native="search" :placeholder="searchPlaceholder"></pt-search>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    currentUserMessage () {
      return this.$store.state.currentUserMessage || {};
		},
	},
	props:{
		searchPlaceholder:String
	},
	data(){
		return{
			searchKey:null
		}
	},
	watch:{
	},
	methods:{
		toSetting(){
			console.log(2)
			this.$router.push('/user-setting')
		},
		search(){
			this.$emit('input',this.searchKey)
		}
	}
};
</script>
<style module lang="less">
@import "~less/var.less";
.header-message {
  position: relative;
  :global {
    .search {
      padding: 0 30px;
      position: absolute;
      width: 100%;
      box-sizing: border-box;
      top: 130px;
    }
  }
}
.list-header-banner {
  background: url("../../assets/images/pt-trust-order-banner.png") no-repeat;
  height: 150px;
  width: 100%;
  padding: 40px 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  :global {
    .left-logo-message {
      width: 50%;
      img {
        width: 100%;
      }
    }
    .right-user-message {
      color: #fff;
      font-size: @font-size-normal;
      align-items: center;
      display: flex;
    }
  }
}
</style>
