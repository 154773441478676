import getEnumeration from "formats/enumeration"
import { trustStatus } from "json/bill-options"
import { formatDateObject, formatDate } from '@/utils/formatUtils/date.js'
import { dayType, cycleChange, terrace } from 'json/lab-order'

export default {
  getBillData (item){
    item.labExamItemNames = "";
    if (item.labExamItemList && item.labExamItemList.length) {
      item.labExamItemList.forEach((etem) => {
        item.labExamItemNames = item.labExamItemNames ? item.labExamItemNames + "/" + etem.itemName : etem.itemName;
      });
    }
    item.billStatus = getEnumeration(item.billStatusEnum, trustStatus)
    let startDate = item.startDate && formatDateObject(item.startDate, 'dateSlash')
    let deliveryDate = item.deliveryDate && formatDateObject(item.deliveryDate, 'dateSlash')
    item.term = startDate + '-' + (deliveryDate ? deliveryDate : '')
    return item
  },
  formatSampleDetail (ele) {
    ele.color_text = ele.color
    ele.shape_text = ele.shape
    ele.pack_text = ele.pack
    ele.storage_text = ele.storage
    ele.specificationModel_text = ele.specificationModel
    ele.deviate_text = ele.labExamItem && (ele.labExamItem.deviate === null ? '--' : ele.labExamItem.deviate ? '是' : '否')
    ele.delivery_date = ele.deliveryDate && formatDate(ele.deliveryDate, 'date')
    ele.last_modified_date = ele.lastModifiedDate && formatDate(ele.lastModifiedDate, 'date')
    ele.orderStatusEnum_text = ele.orderStatusEnum && getEnumeration(ele.orderStatusEnum, sampleStatusList)
    // ele.order_product_date =  ele.orderProductDate&&formatDate(ele.orderProductDate,'date')
    ele.order_product_date =  ele.orderProductDate
    if (ele ?.otherInfo) {
      ele.otherInfo.order_created_date = formatDate(ele.otherInfo ?.orderCreatedDate)
      ele.otherInfo.order_received_date = formatDate(ele.otherInfo ?.orderReceivedDate)
      ele.otherInfo.order_assign_date = formatDate(ele.otherInfo ?.orderAssignDate)
      ele.otherInfo.order_get_date = formatDate(ele.otherInfo ?.orderGetDate)
      ele.otherInfo.order_exam_date = formatDate(ele.otherInfo ?.orderExamDate)
      ele.otherInfo.order_data_received_date = formatDate(ele.otherInfo ?.orderDataReceivedDate)
      ele.otherInfo.order_report_created_date = formatDate(ele.otherInfo ?.orderReportCreatedDate)
      ele.otherInfo.order_report_check_date = formatDate(ele.otherInfo ?.orderReportCheckDate)
      ele.otherInfo.order_back_date = formatDate(ele.otherInfo ?.orderBackDate)
      ele.otherInfo.order_recovery_date = formatDate(ele.otherInfo ?.orderRecoveryDate)
    }

    ele.back_text = ele.back === null ? '--' : ele.back ? '是' : '否'
    return ele
  },
  formatBillDetail (ele) {
    ele.changeCycle = ele.testCycle && ele.dayType ? cycleChange(ele.testCycle) + (ele.dayType && getEnumeration(ele.dayType, dayType)) : '--'
    ele.delivery_type = ele.deliveryType ? ele.deliveryType.map(e => getEnumeration(e, terrace)).toString() : ''
    ele.back_text = ele.back === null ? '--' : ele.back ? '是' : '否'
    ele.deviate_text = ele.deviate === null ? '--' : ele.deviate ? '是' : '否'
    ele.settlement_text = ele.settlement === null ? '--' : ele.settlement ? '已结算' : '未结算'
    ele.isCall_text = ele.isCall === null ? '--' : ele.isCall ? '已催缴' : '未催缴'
    ele.invoice_text = ele.invoice === null ? '--' : ele.invoice ? '已开票' : '未开票'
    ele.urgent_text = ele.urgent === null ? '--' : ele.urgent ? '加急' : ''
    ele.delivery_date = formatDate(ele.deliveryDate, 'date')
    ele.created_date = formatDate(ele.createdDate, 'date')
    ele.start_date = formatDate(ele.startDate, 'date')
    ele.billStatusEnum_text = getEnumeration(ele.billStatusEnum, trustStatus)
    ele.orderSummaryList = ele.orderSummaryList ?.map(e => this.formatSampleDetail(e))
    ele.preOrderSummaryList = ele.preOrderSummaryList ?.map(e => this.formatSampleDetail(e))
    if (ele ?.otherInfo) {
      ele.otherInfo.bill_settler_date = ele ?.otherInfo ?.billSettlerDate ? formatDate(ele.otherInfo.billSettlerDate) : '--'
      ele.otherInfo.bill_drawer_date = ele ?.otherInfo ?.billDrawerDate ? formatDate(ele.otherInfo.billDrawerDate) : '--'
    }
    ele ?.labExamItemList ?.forEach(item => {
      item.changeCycle = item.testCycle && item.dayType && (cycleChange(item.testCycle) +  getEnumeration(item.dayType, dayType))
      item.deviate_text = item.deviate ? '是' : "否"
    })
    return ele
  }
};