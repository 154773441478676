<template>
  <wt-grid col="3" :class="$style['statistics-data']">
    <wt-box class="item">
      <div class="mian number">{{data[labels[0].key]}}</div>
      <div class="name">{{labels[0].label}}</div>
    </wt-box>
    <wt-box class="item">
      <div class="intensify number">{{data[labels[1].key]}}</div>
      <div class="name">{{labels[1].label}}</div>
    </wt-box>
    <wt-box class="item">
      <div class="overdue number">{{data[labels[2].key]}}</div>
      <div class="name">{{labels[2].label}}</div>
    </wt-box>
  </wt-grid>
</template>
<script>
export default {
  props:{
    data:{
      type:Object,
      default:function () {
        return {}
      }
    },
    labels:{
      type:Array,
      default:() => {
        return [
          {
            label:"委托单",
            key:'billCount'
          },
          {
            label:"加急委托",
            key:'billUrgentCount'
          },
          {
            label:'逾期委托',
            key:'billOverDueCount'
          }
        ]
      }
    }
  }
};
</script>
<style module lang="less">
@import "~less/var.less";
.statistics-data {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 0;
  :global {
		.number{
			font-size:@font-size-number
		}
    .intensify {
      color: @intensify-color;
    }
    .overdue {
      color: @overdue-color;
    }
    .name {
      font-size: @font-size-tip;
      padding-top: 10px;
      color: @font-of-grey;
    }
  }
}
</style>
