<template>
    <pt-trust-order-data></pt-trust-order-data>
</template>

<script>
// @ is an alias to /src
import ptTrustOrderData from "cps//pt-trust-order/withData";
export default {
  name: "Home",
  components: {
    ptTrustOrderData
  }
};
</script>
