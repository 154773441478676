export default [{
  path: '/trust-order-detail/:id',
  name: 'trust-order-detail',
  component: resolve => require(['views/trust-order-detail'], resolve),
  props: true,
  meta: { view: 'tabbar', auth: true }
}, {
  path: '/bill-checked-reject/:id',
  name: 'bill-checked-reject',
  props: true,
  component: resolve => require(['views/bill-checked-reject'], resolve),
  meta: { view: 'defaultView', auth:true }
},{
  path: '/bill-receive/:isNormal/:id',
  name: 'bill-receive',
  props: true,
  component: resolve => require(['views/bill-receive'], resolve),
  meta: { view: 'defaultView', auth:true }
} ,{
  path: '/bill-delay/:id',
  name: 'bill-delay',
  props: true,
  component: resolve => require(['views/bill-delay'], resolve),
  meta: { view: 'defaultView', auth:true }
},   {
  path: '/distribute-task/:id',
  name: 'distribute-task',
  props:true,
  component: resolve => require(['views/distribute-task'], resolve),
  meta: { view: 'defaultView', auth:true }
},   {
  path: '/invoice/:id',
  name: 'invoice',
  props:true,
  component: resolve => require(['views/invoice'], resolve),
  meta: { view: 'defaultView', auth:true }
},   {
  path: '/bill-back/:id',
  name: 'bill-back',
  props:true,
  component: resolve => require(['views/bill-back'], resolve),
  meta: { view: 'defaultView', auth:true }
}]