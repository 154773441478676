import { formatDateObject } from "utils/date";

import Vue from "vue";
// import state from '@/store/state'
import { prototype } from "@/prototype";

let formateDateFull = function (date) {
  return formatDateObject(date, "YYYY-MM-DD HH:mm:ss");
};
// 构造wt-loadmore组件数据工具方法
export function builderLoadMoreData (response) {
  let { data, headers } = response;
  let totalNumber = Number(headers["x-total-count"]);
  return { data, totalNumber };
}

/**
 * 带有OSS处理样式格式化资源路径，如果style为空，则使用了默认样式
 * @param {*} url
 * @param {*} style
 * @param {*} isPrivate
 */
export function normalizeResourceWithStyle (
  url,
  style = null,
  isPrivate = false
) {
  if (_.isString(url) && url.length > 0) {
    let styleParam = vm.$store.state["compress"];
    if (style) {
      styleParam = `?x-oss-process=style/${style}`;
    }
    if (url.indexOf("x-oss-process=style") > -1) {
      styleParam = "";
    }
    // 如果是直接以http或者https开头的资源，已经是完整路径了，不需要再拼接
    if (url.indexOf("http") > -1) {
      return url + styleParam;
    }
    let { $privateResourcesUrl, $publicResourcesUrl } = prototype;
    if (isPrivate) {
      // TODO: 私有资源的样式参数还不对
      // return $privateResourcesUrl + url + styleParam
      return $privateResourcesUrl + url;
    } else {
      return $publicResourcesUrl + url + styleParam;
    }
  } else {
    console.warn(`url:‘${url}’并非一个有效的图片地址！`);
    return "";
  }
}

// 预处理资源地址
export function normalizeResource (url, isPrivate = false) {
  return normalizeResourceWithStyle(url, null, isPrivate);
}

export let rate = 100;

// 处理价格，当前以分为单位，需要除以100
export function fixPrice (price, fixed = 2) {
  return Number(price / rate).toFixed(fixed);
}

// 格式化价格
export function formatPrice (price, unit, type = "zh_CH", fixed = 2) {
  if (_.isNumber(price)) {
    price = fixPrice(price, fixed);
    type = type === "NONE" ? "" : type === "zh_CH" ? "¥" : "$";
    if (unit === "LONG_TERM") {
      unit = "月";
    } else if (unit === "SHORT_TERM") {
      unit = "日";
    }
    if (price >= 0) {
      return type + price;
    } else {
      return `-${type}${Math.abs(price)}`;
    }
  }
  return "--";
}
// 所需积分格式化
export function needIntegrals (integral, unit = "积分") {
  if (_.isNumber(integral)) {
    return integral + unit;
  }
  return "--";
}


// 获取默认地址
export function findDefaultAddress (shippingAddresses) {
  if (!_.isArray(shippingAddresses)) {
    throw new Error("findDefaultAddress方法参数必须是一个数组");
  }
  return (
    shippingAddresses.find((address) => {
      return address.isDefault;
    }) || {}
  );
}


// 格式化价格明细及合计
export function formatPaymentSummary (paymentSummary, isIntegral) {
  if (!_.isObject(paymentSummary)) {
    throw new Error("formatPaymentSummary参数必须是一个对象");
  }
  let res = {};
  let { total, items } = paymentSummary;
  res.paymentSummary = {
    total: isIntegral ? needIntegrals(total) : formatPrice(total),
    totalNum: Number(fixPrice(total)),
    totalAmout: total,
    items: items.map((item) => {
      /* 临时代码 */
      // 如果价格是负数，将name字段强制改为“折扣”
      if (item.amount < 0) {
        item.meta = item.name;
        item.name = "折扣";
      }
      /* 临时代码 end */
      return {
        ...item,
        amount: formatPrice(item.amount)
      };
    })
  };
  return res;
}
// 格式化支付方式-价格明显
export function formatPaymentModeSummary (paymentModeSummary) {
  let res = [];
  paymentModeSummary.forEach((item) => {
    res.push({
      ...item,
      amount: formatPrice(item.amount)
    });
  });
  return res;
}
export function formatImgName (str) {
  let ss = str.replace(/\//g, "");
  return ss;
}
// 去除字符串中所有空格/收尾空格
export function getDeleteSpace (str, isAll) {
  let result;
  if (isAll) result = str.replace(/\s+/g, "");
  else result = str.trim();
  return result;
}
/**
 * 格式化推荐、活动位数据
 * @param {Array|Object} data
 */
export function formatePromotions (data) {
  if (_.isArray(data)) {
    if (data.length === 0) {
      // 如果长度为0，则填充一份默认的空数据，用于占位
      return [
        {
          url: "javascript:void(0);",
          image: ""
        }
      ];
    }
    return data.map((item) => {
      return {
        url: item.url,
        image: normalizeResourceWithStyle(item.banner, "png-filter")
      };
    });
  } else {
    return {
      url: data.url,
      image: normalizeResourceWithStyle(data.banner, "png-filter")
    };
  }
}

/**
 * 格式化图片，使其符合pt-slide 或 cube-slide组件要求
 * @param {Array|Object} data
 */
export function formatePicturesToSlide (data) {
  if (_.isArray(data)) {
    if (data.length === 0) {
      // 如果长度为0，则填充一份默认的空数据，用于占位
      return [
        {
          url: "javascript:void(0);",
          image: ""
        }
      ];
    }
    return data.map((item) => {
      return {
        url: "javascript:void(0);",
        image: normalizeResourceWithStyle(item, "png-filter")
      };
    });
  } else {
    return {
      url: "javascript:void(0);",
      image: normalizeResourceWithStyle(data, "png-filter")
    };
  }
}


// 格式化活动对象（秒杀，限时折扣等）
export function formatPromotion (data) {
  let isActivity = false;
  if (!data) {
    // 如果没有活动
    return {
      isActivity
    };
  }
  let { promotionData, detailData, memberRewards } = data;
  let { startTime, endTime } = promotionData;
  detailData = detailData[0];
  let discountGoodsData = detailData.discountGoodsData;
  let priceArr = discountGoodsData.map((item) => item.buyerCashBack);
  let minPrice = Math.min(...priceArr);
  let maxPrice = Math.max(...priceArr);
  let shareCashBackArr = discountGoodsData.map((item) => item.sharerCashBack);
  // console.log('shareCashBackArr', shareCashBackArr)
  let shareCashBackArrNew = [];
  shareCashBackArr.forEach((item) => {
    if (item) {
      shareCashBackArrNew.push(item);
    }
  });
  let minShareMoney = shareCashBackArrNew.length
    ? Math.min(...shareCashBackArrNew)
    : null;
  let maxShareMoney = shareCashBackArrNew.length
    ? Math.max(...shareCashBackArrNew)
    : null;
  let sharerCouponsBack = discountGoodsData.map(
    (item) => item.sharerCouponsBack
  );
  let shareCashBackAmountMin = minShareMoney && `${formatPrice(minShareMoney)}`;
  let shareCashBackAmountMax = maxShareMoney && `${formatPrice(maxShareMoney)}`;
  let shareCashBackAmount =
    shareCashBackAmountMin &&
    shareCashBackAmountMax &&
    (shareCashBackAmountMax === shareCashBackAmountMin
      ? shareCashBackAmountMin
      : shareCashBackAmountMin + "+");
  let buyerCashBackAmount =
    priceArr.length > 1 && minPrice !== maxPrice
      ? `${formatPrice(minPrice)}~${formatPrice(maxPrice)}`
      : minPrice === maxPrice && minPrice
        ? `${formatPrice(minPrice)}`
        : !minPrice && !maxPrice
          ? null
          : discountGoodsData[0] &&
        (discountGoodsData[0].buyerCashBack
          ? `${formatPrice(discountGoodsData[0].buyerCashBack)}`
          : null);
  let now = Date.now();
  if (now > startTime && now < endTime) {
    isActivity = true;
  }
  // 星荐官奖励数据处理
  if (memberRewards && memberRewards.length) {
    memberRewards.forEach((item) => {
      item.upperStarAmount = item.upperStar.amount;
      item.lowerStarAmount = item.lowerStar.amount;
    });
    let upperStarMinAmount = minValueMethod(memberRewards, "upperStarAmount");
    let lowerStarMinAmount = minValueMethod(memberRewards, "lowerStarAmount");
  }
  return {
    ...detailData,
    discountGoodsData,
    ...promotionData,
    isActivity,
    buyerCashBackAmount,
    shareCashBackAmount,
    sharerCouponsBack,
    promotion_type: promotionData.promotionType
  };
}

// 格式化发货（订单）信息
export function formatExpressDetails (data) {
  if (!data) {
    return null;
  }
  let {
    // courierName, courierNumber,
    samples,
    time
  } = data;
  return {
    ...data,
    time: formateDateFull(time),
    sampleIds: samples.map((item) => item.sampleId)
  };
}

// 格式化退货（订单）信息
export function formatRefundmentExpressDetails (data) {
  if (!data) {
    return null;
  }
  let {
    // samples,
    applyTime,
    amount
  } = data;
  return {
    ...data,
    amount: formatPrice(amount),
    time: formateDateFull(applyTime)
    // sampleIds: []// samples.map(item => item.sampleId)
  };
}

export function formatRate (rate, dot = 2) {
  return (Number(rate) * 100).toFixed(dot) + "%";
}

// 格式化里程碑数据为组件需要的格式
export function formatMilestoneData (data, isDetail) {
  /* global vm */
  data.milestones = data.milestones || [];
  data.isDefault = false;
  data.milestones = data.milestones.map((item) => ({
    type: item.type,
    icon: `${process.env.VUE_APP_RESOURCR_HTTP_PATH}/milepost/${
      isDetail ? item.type + "-back" : item.type
    }.png`,
    iconLite: `${process.env.VUE_APP_RESOURCR_HTTP_PATH}/milepost/${
      isDetail ? item.type + "-back" : item.type
    }--lite.png`,
    id: item.id,
    content: item.content,
    date: formatDateObject(item.time, "YYYY年MM月DD日")
  }));
  /* 临时代码 */
  let MEMBER = data.milestones.find((item) => item.type === "MEMBER");
  if (MEMBER) {
    delete MEMBER.iconLite;
  }
  return data;
}
export function toSequence (attr, rev) {
  // 第二个参数没有传递 默认升序排列
  if (rev === undefined) {
    rev = 1;
  } else {
    rev = rev ? 1 : -1;
  }
  return function (a, b) {
    a = a[attr];
    b = b[attr];
    if (a < b) {
      return rev * -1;
    }
    if (a > b) {
      return rev * 1;
    }
    return 0;
  };
}
// 求数组中某个字段的最大值
export function maxValueMethod (arr, filed) {
  let maxValue = Math.max.apply(
    Math,
    arr.map(function (o) {
      return filed ? o[filed] : o;
    })
  );
  return maxValue;
}
// 求数组中某个字段的最小值
export function minValueMethod (arr, filed) {
  let minValue = Math.min.apply(
    Math,
    arr.map(function (o) {
      return filed ? o[filed] : o;
    })
  );
  return minValue;
}
// 判断对象是否为空
export function isEmptyObj (obj) {
  for (var key in obj) {
    return false;
  }
  return true;
}
// 上传/获取的时候，钱数乘以/除以100,当get为true时，则为获取，除以100
export function cashCount (val, get, number) {
  number = number || 0;
  val = typeof val === "number" ? val : parseFloat(val);
  let value;
  if (get) {
    value = number ? (val / 100).toFixed(number) : val / 100;
  } else {
    value = val * 100;
  }
  return value;
}
export function formatOssUrl (src, paltform = "wechat", imageStyle = true) {
  let curSrc = src.replace(/\s+/g, "");
  return normalizeResourceWithStyle(
    `${prototype.$resourcePath}/${paltform}/${curSrc}`,
    imageStyle ? "png-filter" : null
  );
}
export function isEmptyObject (obj) {
  for (var key in obj) {
    return false;
  }
  return true;
}
// 获取某连接中问号后面的某参数
export function GetQueryData (url, name) {
  if (!name) {
    console.log("获取的参数名为必传参数");
  }
  if (url.indexOf("?") === -1) {
    return null;
  }

  let data = url.split("?");
  let obj = {};
  if (data && data.length > 1) {
    if (data[1].indexOf("&") !== -1) {
      let arr = data[1].split("&");
      arr.forEach((item) => {
        obj[item.split("=")[0]] = item.split("=")[1];
      });
    } else {
      let arr = data[1];
      obj[arr.split("=")[0]] = arr.split("=")[1];
    }
  }
  return obj[name];
}

// 将字符串某几个位置的字符替换成符号或固定样式，如星号’*‘
// 第一个参数（str）为传入的字符串，第二个参数（afterLocation）为保留字符串尾部多少位的显示，第三个参数（beforeLocation）为保留字符串头部多少位的显示。
// 最后一个参数（symbol）为替换隐藏字符的符号。默认为”*“
export function strReplace (str, afterLocation, beforeLocation, symbol = "*") {
  if (beforeLocation) {
    return str.substr(0, beforeLocation) + Array(str.length).join(symbol);
  } else {
    return Array(str.length).join(symbol) + str.substr(-afterLocation);
  }
}
// 获取某时间戳所在日期的0点0分0秒
export function dateZero (date, type) {
  let data = date;
  if (type === "timestamp" || !type) {
    data = new Date(data);
  }
  data.setHours(0);
  data.setMinutes(0);
  data.setSeconds(0);
  data.setMilliseconds(0);
  if (type === "timestamp" || !type) {
    data = data.getTime();
  }
  return data;
}
// 获取当前页面链接的指定参数
export function getUrlParams (name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); // 构造一个含有目标参数的正则表达式对象
  var r = window.location.search.substr(1).match(reg); // 匹配目标参数
  if (r != null) return unescape(r[2]);
  return null;
}
// 清空对象里的内容,出入对象，返回空对象
export function emptyObject (obj) {
  for (var key in obj) {
    Vue.delete(obj, key);
  }
}
// 获取某开始时间之后的几个月（某月的最后一天或某月的当天）/几天/几年(某年的最后一天或某年后的当天)后的某个时间
// start:开始时间
// numberOfYear增加的年份【多少年之后或之前】（可为负数，即几年之前）
// numberOfMonth增加的月份【几个月之后或之前】（可为负数，即几个月之前）
// numberOfDay增加的天数【几天之后或之前】（可为负数。即几天之前）
// isLastDayOfYear表示是否获取某年的最后一天
// isLastDayOfmonth表示是否获取某月的最后一天
// isLastDayOfDay表示是否获取获取某天的最后一分钟
// 返回的数据是时间戳
export function getEndDate (data) {
  let {
    start,
    numberOfYear,
    numberOfMonth,
    numberOfDay,
    isLastDayOfYear,
    isLastDayOfmonth,
    isLastDayOfDay
  } = data;
  let date = start ? new Date(start) : new Date();
  let startYear = date.getFullYear(); // 获取开始时间的年
  let startMonth = date.getMonth() + 1;
  let startDay = date.getDate();
  let endMonth01 = numberOfMonth ? startMonth + numberOfMonth : startMonth;
  let endMonth = isLastDayOfYear
    ? 12
    : endMonth01 > 12
      ? endMonth01 % 12
      : endMonth01; // 需获取的月份，当前时间之后几个月的月份数据
  let endYear01 = numberOfYear ? startYear + numberOfYear : startYear;
  let endYear = endMonth01 > 12 ? endYear01 + 1 : endYear01; // 需获取的年份
  let endDay01 = numberOfDay ? startDay + numberOfDay : startDay;
  let dayNumber = new Date(endYear, endMonth, 0).getDate(); // 指定月份的天数
  let endDay =
    isLastDayOfmonth || isLastDayOfYear
      ? dayNumber
      : endDay01 > dayNumber
        ? endDay01 % dayNumber
        : endDay01; // 需获取的日期
  let endTime =
    endYear +
    "/" +
    endMonth +
    "/" +
    endDay +
    (isLastDayOfDay ? " " + "23:59:59" : "");
  let endDate = new Date(endTime).getTime();
  return endDate;
}
