<template>
  <div :class="$style['page-tabbar']"> 
		<wt-view :class="$style['app-main__view']"></wt-view>
		<pt-tabbar class="tabbar" v-if="hasTrust"></pt-tabbar>
	</div>
</template>

<script>
import ptTabbar from 'cps/pt-tabbar/withData'
import {hasPermissionsOfTrust} from "formats/permissions.js"
export default {
  name: "page-tabbar",
  components: {ptTabbar},
  computed: {},
  data () {
    return {
      tabs:[
        {
          lable:"委托单",
          icon:""
        }
      ],
      hasTrust:true
    };
  },
  watch:{
    '$store.state.currentUserMessage':{
      handler:function (val){
        this.hasTrust = hasPermissionsOfTrust()
      },
      immediate:true
    }
  },
};
</script>
<style module lang="less">
@import "~less/var.less";
.page-tabbar {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
	box-sizing: border-box;
	background-color:@page-background-color;
	:global{
	.wt-view{
		height: 100%;
		padding-bottom:50px;
		box-sizing:border-box;
	}
		.tabbar {
    position:fixed!important;
    bottom:0rem;
    left:0;
    right:0;
    //position: relative;
    z-index: 9;
    // border-top: 1px solid #eee;
    height: 50px;;
    padding-top:0.134rem;
    padding-bottom:0.134rem;
}
	}
}
</style>
